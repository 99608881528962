import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as styles from '../css/pages/Join.module.scss';

import SEO from '../components/layout/SEO';
import Layout from '../components/layout/Layout';
import CharityJoinForm from '../components/forms/CharityJoinForm/CharityJoinForm';

const Join = () => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
        }
    `);

    const schemaData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://www.joinripples.org/#organization',
                name: 'Ripples',
                url: 'https://www.joinripples.org/',
                sameAs: [],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://www.joinripples.org/#logo',
                    url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                    width: 500,
                    height: 500,
                    caption: 'Ripples',
                },
                image: {
                    '@id': 'https://www.joinripples.org/#logo',
                },
            },
            {
                '@type': 'WebSite',
                '@id': 'https://www.joinripples.org/#website',
                url: 'https://www.joinripples.org/',
                name: 'Ripples',
                description: 'Donate pennies as you spend',
                publisher: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
            },
            {
                '@type': 'WebPage',
                '@id': 'https://www.joinripples.org/join/#webpage',
                url: 'https://www.joinripples.org/join/',
                inLanguage: 'en-GB',
                name: 'Join Ripples as a good cause | Ripples',
                isPartOf: {
                    '@id': 'https://www.joinripples.org/#website',
                },
                datePublished: '2020-04-08T16:10:36+00:00',
                dateModified: data.currentBuildDate.currentDate,
            },
        ],
    };

    return (
        <>
            <SEO
                title="Joining Ripples"
                description="Joining Ripples is easy. We just need a few things to get you set up. Once we have received the information below, we’ll get back to you with next steps"
                path="/join/"
                schemaData={[schemaData]}
            />
            <Layout mainClass={styles.Join}>
                <section className={styles.intro}>
                    <h1>Join Ripples</h1>
                    <hr />
                    <p>
                        Joining Ripples is easy. We just need a few things to
                        get you set up. Once we have received the information
                        below, we’ll get back to you with next steps.
                    </p>
                    <p>
                        For further information or to arrange a call to discuss
                        how we can support you, please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>
                        .
                    </p>
                </section>
                <section className={styles.checklist}>
                    <h2>Good cause checklist</h2>
                    <p>
                        You can supply this information using the form below or
                        email it to{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>
                        .
                    </p>
                    <div className={styles.requirements}>
                        <div className={styles.list}>
                            <h3>Required</h3>
                            <ul>
                                <li>Your logo</li>
                                <li>50-150 word blurb about your cause</li>
                                <li>
                                    Completed{' '}
                                    <a
                                        href="/PDF/collaboration-agreement/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Collaboration Agreement
                                    </a>
                                </li>
                                <li>Bank account details</li>
                            </ul>
                        </div>
                        <div className={styles.list}>
                            <h3>Optional but recommended</h3>
                            <ul>
                                <li>Images and/or video URL</li>
                                <li>Information on current projects</li>
                                <li>Fundraising target (if applicable)</li>
                                <li>Mission statement</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className={styles.form}>
                    <h2>Joining form</h2>
                    <hr />
                    <CharityJoinForm />
                </section>
            </Layout>
        </>
    );
};

export default Join;
