/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import classnames from 'classnames';

import * as styles from '../../../css/components/Form.module.scss';

const CharityJoinForm = () => {
    const handleSubmitForm = (values) => {
        fetch('/', {
            method: 'POST',
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            body: encode({
                'form-name': 'charity join',
                ...values,
            }),
        });
        window.dataLayer.push({ event: 'submitCharityForm' });
    };

    const phoneRegEx =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const accountNumberRegEx = /^[0-9]{8}$/;
    const accountSortCodeRegEx = /^[0-9]{6}$/;

    const charityJoinFormSchema = yup.object({
        organisationName: yup
            .string()
            .required('Organisation or fundraiser name required'),
        organisationWebsite: yup.string(),
        organisationRegistrationNumbers: yup.string(),
        leadName: yup.string().required('Lead contact name required'),
        leadEmail: yup
            .string()
            .email('Invalid email address')
            .required('Lead contact email required'),
        leadPhoneNumber: yup
            .string()
            .matches(phoneRegEx, 'Invalid phone number')
            .required('Lead contact phone number is required'),
        organisationLogo: yup.mixed().required('Logo is required'),
        organisationAbout: yup
            .string()
            .required('About organisation or fundraiser is required'),
        missionStatement: yup.string(),
        organisationVideo: yup.string(),
        organisationHero: yup.mixed(),
        accountName: yup.string().required('Account name is required'),
        accountNumber: yup
            .string()
            .matches(accountNumberRegEx, 'Invalid account number')
            .required('Account number is required'),
        accountSortCode: yup
            .string()
            .matches(accountSortCodeRegEx, 'Invalid sort code')
            .required('Account sort code is required'),
        project1: yup.string(),
        project1Image: yup.mixed(),
        project2: yup.string(),
        project2Image: yup.mixed(),
        project3: yup.string(),
        project3Image: yup.mixed(),
        donation250: yup.string(),
        donation500: yup.string(),
        donation750: yup.string(),
        donation1000: yup.string(),
        donation1500: yup.string(),
        donation2000: yup.string(),
        donation3000: yup.string(),
        donation5000: yup.string(),
        donation10000: yup.string(),
        hearAboutUs: yup.string(),
        collaborationAgreement: yup
            .bool()
            .oneOf([true], 'Agreeing the Collaboration Agreement is required'),
    });

    const initialValues = {
        'form-name': 'charity join',
        organisationName: '',
        organisationWebsite: '',
        organisationRegistrationNumbers: '',
        leadName: '',
        leadEmail: '',
        leadPhoneNumber: '',
        organisationLogo: '',
        organisationAbout: '',
        missionStatement: '',
        organisationVideo: '',
        organisationHero: '',
        accountName: '',
        accountNumber: '',
        accountSortCode: '',
        project1: '',
        project1Image: '',
        project2: '',
        project2Image: '',
        project3: '',
        project3Image: '',
        donation250: '',
        donation500: '',
        donation750: '',
        donation1000: '',
        donation1500: '',
        donation2000: '',
        donation3000: '',
        donation5000: '',
        donation10000: '',
        hearAboutUs: '',
        collaborationAgreement: false,
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmitForm(values)}
            validationSchema={charityJoinFormSchema}>
            {(formik) => (
                <Form
                    name="charity join"
                    className={styles.Form}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field">
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input
                        type="hidden"
                        name="form-name"
                        value="charity join"
                    />
                    <label hidden>
                        Don’t fill this out: <input name="bot-field" />
                    </label>

                    <h3>General information</h3>
                    <div className={styles.formGroup}>
                        <label htmlFor="organisationName">
                            Organisation or fundraiser name <span>*</span>
                        </label>
                        <Field name="organisationName">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="organisationName"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="organisationName">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="organisationWebsite">
                            Organisation or fundraiser website
                        </label>
                        <Field name="organisationWebsite">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="organisationWebsite"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="organisationRegistrationNumbers">
                            Organisation registration number
                        </label>
                        <Field name="organisationRegistrationNumbers">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="organisationRegistrationNumbers"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="leadName">
                            Lead contact name <span>*</span>
                        </label>
                        <Field name="leadName">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="leadName"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="leadName">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="leadEmail">
                            Lead contact email, please use your work email if
                            applicable <span>*</span>
                        </label>
                        <Field name="leadEmail">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="email"
                                    id="leadEmail"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="leadEmail">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="leadPhoneNumber">
                            Lead contact phone number <span>*</span>
                        </label>
                        <Field name="leadPhoneNumber">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="leadPhoneNumber"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="leadPhoneNumber">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={classnames(styles.formGroup, styles.file)}>
                        <label htmlFor="organisationLogo">
                            Organisation or fundraiser logo <span>*</span>
                        </label>
                        <input
                            type="file"
                            id="organisationLogo"
                            name="organisationLogo"
                            disabled={formik.isSubmitting}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'organisationLogo',
                                    e.target.files[0],
                                );
                            }}
                        />
                        <ErrorMessage name="organisationLogo">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div
                        className={classnames(
                            styles.formGroup,
                            styles.textarea,
                        )}>
                        <label htmlFor="organisationAbout">
                            About organisation or fundraiser <span>*</span>
                        </label>
                        <Field
                            name="organisationAbout"
                            id="organisationAbout"
                            component="textarea"
                            placeholder="Provide a compelling 50-150 word description about your organisation or fundraiser"
                        />
                        <ErrorMessage name="organisationAbout">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="missionStatement">
                            Organisation or fundraiser mission statement
                        </label>
                        <Field name="missionStatement">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="missionStatement"
                                    disabled={formik.isSubmitting}
                                    placeholder="Your organisation's mission statement"
                                />
                            )}
                        </Field>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="organisationVideo">
                            Organisation or fundraiser video URL
                        </label>
                        <Field name="organisationVideo">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="organisationVideo"
                                    disabled={formik.isSubmitting}
                                    placeholder="Provide URL to your organisation or fundraiser video"
                                />
                            )}
                        </Field>
                    </div>
                    <div className={classnames(styles.formGroup, styles.file)}>
                        <label htmlFor="organisationHero">
                            Organisation or fundraiser hero image
                        </label>
                        <input
                            type="file"
                            name="organisationHero"
                            id="organisationHero"
                            disabled={formik.isSubmitting}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'organisationHero',
                                    e.target.files[0],
                                );
                            }}
                        />
                    </div>

                    <h3>Bank account details</h3>
                    <div className={styles.formGroup}>
                        <label htmlFor="accountName">
                            Account name <span>*</span>
                        </label>
                        <Field name="accountName">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="accountName"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="accountName">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="accountNumber">
                            Account number <span>*</span>
                        </label>
                        <Field name="accountNumber">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="accountNumber"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="accountNumber">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="accountSortCode">
                            Account sort code <span>*</span>
                        </label>
                        <Field name="accountSortCode">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="accountSortCode"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="accountSortCode">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>

                    <h3>Projects</h3>
                    <p>
                        We want to show potential donors the good work that you
                        are doing. This section is optional but recommended.
                    </p>
                    <details>
                        <summary>Fill out project details</summary>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.textarea,
                            )}>
                            <label htmlFor="project1">Project 1</label>
                            <Field
                                name="project1"
                                id="project1"
                                component="textarea"
                                placeholder="Provide a short summary of a great project or initiative you're running. Include outward links for more information if appropriate"
                            />
                        </div>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.file,
                            )}>
                            <label htmlFor="project1Image">
                                Project 1 image
                            </label>
                            <input
                                type="file"
                                name="project1Image"
                                id="project1Image"
                                disabled={formik.isSubmitting}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'project1Image',
                                        e.target.files[0],
                                    );
                                }}
                            />
                        </div>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.textarea,
                            )}>
                            <label htmlFor="project2">Project 2</label>
                            <Field
                                name="project2"
                                id="project2"
                                component="textarea"
                                placeholder="Provide a short summary of a great project or initiative you're running. Include outward links for more information if appropriate"
                            />
                        </div>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.file,
                            )}>
                            <label htmlFor="project2Image">
                                Project 2 image
                            </label>
                            <input
                                type="file"
                                name="project2Image"
                                id="project2Image"
                                disabled={formik.isSubmitting}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'project2Image',
                                        e.target.files[0],
                                    );
                                }}
                            />
                        </div>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.textarea,
                            )}>
                            <label htmlFor="project3">Project 3</label>
                            <Field
                                name="project3"
                                id="project3"
                                component="textarea"
                                placeholder="Provide a short summary of a great project or initiative you're running. Include outward links for more information if appropriate"
                            />
                        </div>
                        <div
                            className={classnames(
                                styles.formGroup,
                                styles.file,
                            )}>
                            <label htmlFor="project3Image">
                                Project 3 image
                            </label>
                            <input
                                type="file"
                                name="project3Image"
                                id="project3Image"
                                disabled={formik.isSubmitting}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'project3Image',
                                        e.target.files[0],
                                    );
                                }}
                            />
                        </div>
                        <p>
                            Please provide representative examples of what the
                            following donations would do for your organisation,
                            fundraiser or service users. This will allow us to
                            motivate donors by triggering notifications when
                            their donations reach specific amounts. For example,
                            "Your donations have reached £5.00 this week. This
                            has gone towards providing 2 meals for a homeless
                            person today. Thank you." You can provide more than
                            one example for each amount. We understand that you
                            may not have an example for every amount.
                        </p>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation250">&pound;2.50</label>
                            <Field name="donation250">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation250"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation500">&pound;5.00</label>
                            <Field name="donation500">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation500"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation750">&pound;7.50</label>
                            <Field name="donation750">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation750"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation1000">&pound;10.00</label>
                            <Field name="donation1000">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation1000"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation1500">&pound;15.00</label>
                            <Field name="donation1500">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation1500"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation2000">&pound;20.00</label>
                            <Field name="donation2000">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation2000"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation3000">&pound;30.00</label>
                            <Field name="donation3000">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation3000"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation5000">&pound;50.00</label>
                            <Field name="donation5000">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation5000"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="donation10000">&pound;100.00</label>
                            <Field name="donation10000">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        id="donation10000"
                                        disabled={formik.isSubmitting}
                                    />
                                )}
                            </Field>
                        </div>
                    </details>

                    <div className={classnames(styles.formGroup, styles.hear)}>
                        <label htmlFor="hearAboutUs">
                            How did you hear about Ripples?
                        </label>
                        <Field name="hearAboutUs">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    id="hearAboutUs"
                                    disabled={formik.isSubmitting}
                                />
                            )}
                        </Field>
                    </div>

                    <div
                        className={classnames(
                            styles.formGroup,
                            styles.agreement,
                        )}>
                        <label htmlFor="collaborationAgreement">
                            I confirm that we understand and agree to the{' '}
                            <a
                                href="/PDF/collaboration-agreement/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Collaboration Agreement
                            </a>
                        </label>
                        <Field name="collaborationAgreement">
                            {({ field }) => (
                                <input
                                    {...field}
                                    type="checkbox"
                                    id="collaborationAgreement"
                                    checked={field.value}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="collaborationAgreement">
                            {(msg) => <p>{msg}</p>}
                        </ErrorMessage>
                    </div>

                    <button
                        type="submit"
                        disabled={formik.isSubmitting}
                        style={formik.isSubmitting ? { opacity: 0.8 } : {}}>
                        {formik.isSubmitting ? 'Thank you!' : 'Submit'}
                    </button>
                    <FormError formik={formik} />
                </Form>
            )}
        </Formik>
    );
};

export default CharityJoinForm;

function FormError({ formik }) {
    const [formError, setFormError] = useState(null);
    const effect = () => {
        if (formik.submitCount > 0 && !formik.isValid) {
            setFormError(
                <p className={styles.formError}>
                    There are missing required fields.
                </p>,
            );
        } else {
            setFormError(null);
        }
    };
    useEffect(effect, [formik.submitCount, formik.isValid]);
    return formError;
}

function encode(data) {
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
        formData.append(key, data[key]);
    }

    return formData;
}
